<template>
  <component :is="layout">
    <div class="container-md">
      <div class="row fadeInUp" role="main">
        <div class="col-12 col-md-8 offset-md-2">
          <div class="form-row">
            <div class="col-12">
              <div
                class="d-flex flex-column flex-md-row justify-content-between align-items-lg-center align-items-md-start border-bottom border-gray-800 pb-2 mb-4"
              >
                <div
                  v-if="goalSelected && goal.id !== 'skip'"
                  class="px-0 py-1 m-1"
                >
                  <b>Onboarding</b><br />
                  <p class="h3">
                    Thanks for choosing a goal: {{ goal.title }}, now lets get
                    you setup and ready to begin.
                  </p>
                </div>
                <div
                  v-if="goalSelected && goal.id === 'skip'"
                  class="px-0 py-1 m-1"
                >
                  <b>Onboarding to enable Simple E-Commerce Automation & CRM</b
                  ><br />
                  <p class="h3">
                    Automation.app must be connected to your shop. This way we
                    can connect orders, clients, products etc.
                  </p>
                </div>
                <div v-if="!goalSelected" class="px-0 py-1 m-1">
                  <b>Onboarding</b><br />
                  <p class="h3">
                    Select the first optimisation you want to configure
                  </p>
                </div>
                <div v-if="!goalSelected" class="px-0 py-1 m-0 col-3">
                  <button
                    class="btn btn-sm btn-secondary m-1 p-3 pl-5 pr-5 page-intro"
                    @click="skipGoal"
                  >
                    <b>Skip this step</b>
                  </button>
                </div>
              </div>
            </div>
            <!-- eslint-disable vue/no-use-v-if-with-v-for,vue/no-confusing-v-for-v-if -->
            <div
              v-for="goal in goals"
              v-if="!goalSelected"
              :key="goal.id"
              class="col-12 col-md-5 col-lg-4 pt-1 pb-1"
            >
              <div
                class="card card-border shadow-light-lg lift lift-lg goal-card"
                :style="{ 'border-top-color': goal.color }"
                @click.prevent="setGoal(goal)"
              >
                <div class="card-body text-center">
                  <!-- Text -->
                  <p
                    class="font-weight-bold h5 mb-3"
                    :style="{ color: goal.color }"
                  >
                    {{ goal.title }}
                  </p>
                  <p class="font-weight-bold mb-3">
                    {{ goal.description_bold }}
                  </p>
                  <p class="text-muted mb-3">
                    {{ goal.description_text }}
                  </p>

                  <!-- Link -->
                  <button class="btn" :style="{ color: goal.color }">
                    {{ goal.button }}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div v-if="goalSelected" class="form-row">
            <div class="col-12">
              <div class="card border-0 shadow-sm mb-6">
                <div class="card-body">
                  <div class="d-flex flex-grow">
                    <div
                      v-for="step in steps"
                      :key="step.step"
                      class="flex-fill mb-5 border-bottom"
                    >
                      <p class="text-center align-middle">
                        <CheckmarkCircleLine
                          :height="'25'"
                          class="inline mr-2"
                          style="margin-top: -5px;"
                          :fill="[step.completed ? '#3FDC75' : '#000000']"
                        />
                        {{ step.name }}
                      </p>
                    </div>
                  </div>
                  <component
                    :is="getCurrentStep.component"
                    v-if="getCurrentStep"
                    @proceed="stepComplete"
                  />
                  <p v-else class="text-center">
                    Setup completed
                  </p>
                </div>
                <div v-if="!setupComplete" class="card-footer">
                  <button v-if="step > 1" class="btn btn-light" @click="step--">
                    Prev
                  </button>
                  <button
                    v-if="step === 1 && showNext"
                    class="btn btn-outline-primary float-right ml-2"
                    @click="connectStore()"
                  >
                    Install plugin
                  </button>
                  <button
                    v-if="step < steps.length && showNext"
                    class="btn btn-primary float-right"
                    @click="nextStep()"
                  >
                    Next
                  </button>
                </div>
              </div>
            </div>

            <div v-if="setupComplete" class="col-12">
              <div v-if="goal.skip" class="card border-0 shadow-sm mb-6">
                <div class="card-header">
                  <h4 class="h4 text-center">
                    You have successfully completed the platform configuration.
                  </h4>
                </div>
                <div class="card-body">
                  <div class="row justify-content-md-center mt-6 mr-4 ml-4">
                    <p class="h4">
                      Congratulations you are now ready to use automation.app
                    </p>
                    <router-link
                      :to="{ name: 'order.index' }"
                      class="btn btn-primary col-4"
                    >
                      View orders
                    </router-link>
                  </div>
                </div>
              </div>
              <div v-else class="card border-0 shadow-sm mb-6">
                <div class="card-header">
                  <h4 class="h4 text-center">
                    You have successfully completed the platform configuration,
                    now let's focus on how we can help you reach your goal.
                  </h4>
                </div>
                <div class="card-body">
                  <p>
                    In order to help you reach your goal we have identified
                    these elements that needs to be installed:
                  </p>
                  <div v-if="goal.install.tags" class="border-top mb-6">
                    <h4 class="h4">
                      Your chosen goal will install these tags:
                    </h4>
                    <div
                      v-for="tag in goal.install.tags"
                      :key="tag.key"
                      class="mb-1 ml-2"
                    >
                      <CheckmarkCircleLine
                        :height="'25'"
                        class="inline mr-2"
                        style="margin-top: -5px;"
                        :fill="[tag.installed ? '#3FDC75' : '#000000']"
                      />
                      <Tag
                        :slug="tag.slug"
                        :color="tag.color"
                        :name="tag.name"
                      />
                    </div>
                  </div>
                  <div v-if="goal.install.actions" class="border-top mb-6">
                    <h4 class="h4">
                      Your chosen goal will install these automations:
                    </h4>
                    <div
                      v-for="action in goal.install.actions"
                      :key="action.id"
                      class="ml-2"
                    >
                      <p class="font-weight-bold mb-0">
                        <CheckmarkCircleLine
                          :height="'25'"
                          class="inline mr-2"
                          style="margin-top: -5px;"
                          :fill="[action.installed ? '#3FDC75' : '#000000']"
                        />
                        {{ action.name }}
                      </p>
                      <p class="text-muted">
                        {{ action.description }}
                      </p>
                    </div>
                  </div>
                  <div v-if="goal.install.filters" class="border-top mb-6">
                    <h4 class="h4">
                      Your chosen goal will install these filters
                    </h4>
                    <div
                      v-for="filter in goal.install.filters"
                      :key="filter.id"
                      class="ml-2"
                    >
                      <p class="font-weight-bold mb-0">
                        <CheckmarkCircleLine
                          :height="'25'"
                          class="inline mr-2"
                          style="margin-top: -5px;"
                          :fill="[filter.installed ? '#3FDC75' : '#000000']"
                        />
                        {{ filter.name }}
                      </p>
                      <p class="text-muted">
                        {{ filter.description }}
                      </p>
                    </div>
                  </div>
                  <div
                    v-if="!onboardingDone"
                    class="row justify-content-md-center mt-6 mr-4 ml-4"
                  >
                    <p>
                      Click the install button to install all the elements which
                      will help you reach your goal
                    </p>
                    <button
                      class="btn btn-primary m-1 pr-4 pl-4 text-center"
                      @click="install"
                    >
                      <span
                        v-if="installing"
                        class="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      />
                      Install
                    </button>
                  </div>
                  <div
                    v-else
                    class="row justify-content-md-center mt-6 mr-4 ml-4"
                  >
                    <p class="h4">
                      Congratulations you are now ready to use automation.app
                    </p>
                    <p v-if="goal.install.actions">
                      Since you installed actions you should make sure to adjust
                      them to your business needs. Feel free to reach out to our
                      support if you need help.
                    </p>
                    <router-link
                      v-if="goal.install.actions"
                      :to="{ name: 'automations.index' }"
                      class="btn btn-primary col-4"
                    >
                      Review automations
                    </router-link>
                    <router-link
                      v-else
                      :to="{ name: 'order.index' }"
                      class="btn btn-primary col-4"
                    >
                      View orders
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </component>
</template>

<script>
import { mapGetters } from "vuex";
import confetti from "canvas-confetti";
import jsonAPI from "@/api";
import Default from "@/layouts/default.vue";
import SelectableElement from "@/components/Automation/SelectableElement.vue";
// Onboarding steps
import ConnectStore from "@/components/Onboarding/ConnectStore.vue";
import DownloadPlugin from "@/components/Account/DownloadPlugin.vue";
import EmailConfigurator from "@/components/Onboarding/EmailConfigurator.vue";
import EmailTemplate from "@/components/Onboarding/EmailTemplate.vue";
import CheckmarkCircleLine from "@/components/Svg/CheckmarkCircleLine.vue";
import Tag from "@/components/UI/Tag.vue";
// installs
import customer_segmentation from "@/components/Onboarding/recipies/customer_segmentation";
import thank_loyal from "@/components/Onboarding/recipies/thank_loyal";
import winback from "@/components/Onboarding/recipies/winback";
import problematic_orders from "@/components/Onboarding/recipies/problematic_orders";
import analyse_channels from "@/components/Onboarding/recipies/analyse_channels";
import { postToApi } from "@/helpers/apiConnection";
import utilities from "@/helpers/utilities";

var myCanvas = document.getElementById("canvas");
var myConfetti = confetti.create(myCanvas, { resize: true });

export default {
  name: "Onboarding",
  components: {
    Default,
    SelectableElement,
    ConnectStore,
    DownloadPlugin,
    EmailConfigurator,
    EmailTemplate,
    CheckmarkCircleLine,
    Tag,
  },
  provide() {
    return {
      $validator: this.$validator,
    };
  },
  data() {
    return {
      layout: "Default",
      organization: {},
      goal: {
        id: "",
      },
      steps: [
        {
          step: 1,
          name: "Install plugin",
          key: "import_orders",
          component: "DownloadPlugin",
          completed: false,
        },
        {
          step: 2,
          name: "Email configuration",
          key: "configure_driver",
          component: "EmailConfigurator",
          completed: false,
        },
        {
          step: 3,
          name: "Email template",
          key: "create_email_template",
          component: "EmailTemplate",
          completed: false,
        },
        {
          step: 4,
          name: "Product import",
          key: "import_products",
          component: "ConnectStore",
          completed: false,
        },
      ],
      goals: [
        {
          id: "increase_satisfaction",
          title: "Increase customer satisfaction",
          description_bold:
            "Get your Automation.app set up for customer related activities.",
          description_text:
            "Installation and configuration, focused on general setup of customer follow up",
          button: "Get ready to win loyal",
          color: "#02B472",
          install: {},
          skip: true,
        },
        {
          id: "improve_workflow",
          title: "Improve workflows and processes",
          description_bold:
            "Get your Automation.app set up for workflows and process optimisation.",
          description_text:
            "Installation and configuration, focused on general setup of customer workflows",
          button: "Automate processes",
          color: "#FFC204",
          install: {},
          skip: true,
        },
        {
          id: "automation_excellence",
          title: "Build automation excellence in other ways",
          description_bold:
            "The most generalised way to start your experience with Automation.app.",
          description_text:
            "Installation and configuration, focused on a completely general setup",
          button: "Automate processes",
          color: "#02B5FA",
          install: {},
          skip: true,
        },
        {
          id: "win_back",
          title: "Win back one time customers",
          description_bold:
            "6 emails to follow up on customers and reinforce your brand.",
          description_text:
            "Includes: 30 day, 45 day, 70 day and 100 day, 180 day since last order + the “1 year anniversary”",
          button: "Start winning loyal customers",
          color: "#02B472",
          install: winback,
          skip: false,
        },
        {
          id: "customer_segmentation",
          title: "Segmentation for customers",
          description_bold:
            "4 tags for CRM. Build lists based on individual buying behaviour.",
          description_text:
            "Includes: Repeat customer, VIP customer, High revenue, Churning customer",
          button: "Start tagging your CRM",
          color: "#02B472",
          install: customer_segmentation,
          skip: false,
        },
        {
          id: "thank_loyal",
          title: "Thank loyal customers",
          description_bold:
            "3 emails that you send to returning customers in order to show your appreciation.",
          description_text:
            "Includes: Thank you, please review your experience, like us on social media.",
          button: "Send thank you to loyal clients",
          color: "#02B472",
          install: thank_loyal,
          skip: false,
        },
        {
          id: "problematic_orders",
          title: "Failed order follow up",
          description_bold:
            "Email and tags that will improve workflow on orders that might have issues.",
          description_text:
            "Sets failed order tag and sends an email to the client.",
          button: "Start automating process",
          color: "#FFC204",
          install: problematic_orders,
          skip: false,
        },
        {
          id: "analyse_channels",
          title: "Analyze marketing channels",
          description_bold:
            "Improve marketing tracking with information about which channel clients originate from.",
          description_text:
            "Installing referral plugin and turns meta data into 5 client tags from the order data",
          button: "Start analysing",
          color: "#02B5FA",
          install: analyse_channels,
          skip: false,
        },
      ],
      step: 1,
      showNext: false,
      setupComplete: false,
      goalSelected: false,
      installing: false,
      onboardingDone: false,
    };
  },
  computed: {
    ...mapGetters(["getOrganization", "getUser"]),
    getCurrentStep() {
      const steps = this.steps.filter((elem) => {
        return !elem.completed;
      });
      if (steps) {
        return steps.shift();
      }
      return null;
    },
  },
  mounted() {
    const org = this.getOrganization;
    const settings = org.settings;
    if (settings !== null) {
      const status = settings.onboarding;
      const stepComplete = (key) => {
        const exists = status.some(function(st) {
          return st.key === key;
        });
        return exists;
      };
      this.steps.forEach((step, index) => {
        if (stepComplete(step.key)) {
          this.steps[index].completed = true;
        }
      });
      if (this.getCurrentStep) {
        this.showNext = this.getCurrentStep.key === "import_products";
      }
      const hasIncomplete = this.hasIncomplete();
      if (!hasIncomplete) {
        this.setupComplete = true;
      }
    }
  },
  methods: {
    hasIncomplete() {
      return this.steps.some(function(st) {
        return !st.completed;
      });
    },
    setGoal(goal) {
      this.goal = goal;
      jsonAPI.post("/marketing/event", {
        action: `goal-${goal.id}`,
        email: this.getUser.email,
      });
      this.goalSelected = true;
    },
    skipGoal() {
      this.setGoal({
        id: "skip",
        title: "",
        skip: true,
      });
    },
    stepComplete() {
      this.showNext = true;
    },
    nextStep() {
      const currentStep = this.getCurrentStep;
      this.steps.forEach((step, index) => {
        if (step.key === currentStep.key) {
          this.steps[index].completed = true;
        }
      });
      if (this.getCurrentStep) {
        this.showNext = this.getCurrentStep.key === "import_products";
      }
      const hasIncomplete = this.hasIncomplete();
      if (!hasIncomplete) {
        this.setupComplete = true;
        jsonAPI.post("/marketing/event", {
          action: `setup-complete`,
          email: this.getUser.email,
        });
        if (this.goal.skip) {
          this.onboardingDone = true;
          setTimeout(function() {
            var end = Date.now() + 2000;

            var interval = setInterval(function() {
              if (Date.now() > end) {
                return clearInterval(interval);
              }

              myConfetti({
                startVelocity: 30,
                spread: 360,
                ticks: 60,
                origin: {
                  x: Math.random(),
                  y: Math.random() - 0.2,
                },
              });
              jsonAPI.post("/marketing/event", {
                action: `onboarding-complete`,
                email: this.getUser.email,
              });
            }, 200);
          }, 500);
        }
      }
    },
    connectStore() {
      utilities.openWindow(
        "https://" +
          this.getOrganization.domain +
          "/wp-admin/plugin-install.php",
        "Login",
        { width: 800, height: 720 }
      );
      this.nextStep();
    },
    async install() {
      if (this.installing) {
        window.Bus.$emit("flash-message", {
          text: "Installation in progress please wait",
          type: "success",
        });
      }
      this.installing = true;
      if (this.goal.install.tags) {
        for (const tag of this.goal.install.tags) {
          const res = await postToApi("/tag", tag).catch((error) => {
            window.Bus.$emit("flash-message", {
              text: error.message,
              type: "error",
            });
          });
          if (res) {
            tag.installed = true;
          }
        }
      }
      if (this.goal.install.actions) {
        for (const action of this.goal.install.actions) {
          const res = await postToApi("/automationRule", action).catch(
            (error) => {
              window.Bus.$emit("flash-message", {
                text: error.message,
                type: "error",
              });
            }
          );
          if (res) {
            action.installed = true;
          }
        }
      }
      if (this.goal.install.filters) {
        for (const filter of this.goal.install.filters) {
          filter.organization_id = this.getOrganization.id;
          const res = await postToApi("/segment", filter).catch((error) => {
            window.Bus.$emit("flash-message", {
              text: error.message,
              type: "error",
            });
          });
          if (res) {
            filter.installed = true;
          }
        }
      }
      this.installing = false;
      this.onboardingDone = true;
      setTimeout(function() {
        var end = Date.now() + 2000;

        var interval = setInterval(function() {
          if (Date.now() > end) {
            return clearInterval(interval);
          }

          myConfetti({
            startVelocity: 30,
            spread: 360,
            ticks: 60,
            origin: {
              x: Math.random(),
              y: Math.random() - 0.2,
            },
          });
          jsonAPI.post("/marketing/event", {
            action: `onboarding-complete`,
            email: this.getUser.email,
          });
        }, 200);
      }, 500);
    },
  },
};
</script>

<style scoped>
.flex-grow {
  flex: 1 0 auto;
}
.goal-card {
  height: 100%;
}
.goal-card:hover {
  cursor: pointer;
}
</style>
