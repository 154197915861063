<template>
  <div class="fadeInLeft form-row">
    <div>
      <h1 class="h4 font-weight-bold">
        Setup your first email template
      </h1>
      <p class="text-muted mb-2">
        Before we start sending emails to your customers you should
      </p>
      <p class="text-muted">
        Configure an email driver for sending emails. You can also configure
        third party providers like Mailgun, SES etc.
      </p>
    </div>
    <div class="form-row col-12 col-md-6">
      <div class="form-row col-12">
        <div
          class="form-group col-12 required"
          :class="{ 'is-invalid': errors.has('name') }"
        >
          <label for="name" class="control-label">Template name:</label>
          <input
            id="name"
            v-model="mail_config_data.name"
            v-validate="{ required: true }"
            type="text"
            name="name"
            class="form-control"
          />
          <span v-show="errors.has('name')" class="invalid-feedback">{{
            errors.first("name")
          }}</span>
        </div>
      </div>
      <div class="form-group col-12">
        <h5>Background colors</h5>
        <hr />
      </div>
      <div class="form-group col-12">
        <label for="background_color" class="control-label">Body color:</label>
        <input
          id="background_color"
          v-model="mail_config_data.background_color"
          type="text"
          name="background_color"
          class="form-control"
        />
        <div class="d-block mt-3">
          <ColorSelect
            v-for="color in bg_colors"
            :key="color"
            :style="{ background: color }"
            :class="[
              { active_color: color === mail_config_data.background_color },
            ]"
            @click.native="chooseColor('background_color', color)"
          />
        </div>
      </div>
      <div class="form-group col-12">
        <label for="header_color" class="control-label">Header color:</label>
        <input
          id="header_color"
          v-model="mail_config_data.header_color"
          type="text"
          name="header_color"
          class="form-control"
        />
        <div class="d-block mt-3">
          <ColorSelect
            v-for="color in bg_colors"
            :key="color"
            :style="{ background: color }"
            :class="[{ active_color: color === mail_config_data.header_color }]"
            @click.native="chooseColor('header_color', color)"
          />
        </div>
      </div>
      <div class="form-group col-12">
        <label for="footer_color" class="control-label">Footer color:</label>
        <input
          id="footer_color"
          v-model="mail_config_data.footer_color"
          type="text"
          name="footer_color"
          class="form-control"
        />
        <div class="d-block mt-3">
          <ColorSelect
            v-for="color in bg_colors"
            :key="color"
            :style="{ background: color }"
            :class="[{ active_color: color === mail_config_data.footer_color }]"
            @click.native="chooseColor('footer_color', color)"
          />
        </div>
      </div>
      <div class="form-group col-12">
        <h5>Font colors</h5>
        <hr />
      </div>
      <div class="form-group col-md-6">
        <label for="font_color" class="control-label">Font color:</label>
        <input
          id="font_color"
          v-model="mail_config_data.font_color"
          type="text"
          name="font_color"
          class="form-control"
        />
        <div class="d-block mt-3">
          <ColorSelect
            v-for="color in font_colors"
            :key="color"
            :style="{ background: color }"
            :class="[{ active_color: color === mail_config_data.font_color }]"
            @click.native="chooseColor('font_color', color)"
          />
        </div>
      </div>
      <div class="form-group col-md-6">
        <label for="button_color" class="control-label">Button text color:</label>
        <input
          id="button_color"
          v-model="mail_config_data.button_color"
          type="text"
          name="button_color"
          class="form-control"
        />
        <div class="d-block mt-3">
          <ColorSelect
            v-for="color in button_colors"
            :key="color"
            :style="{ background: color }"
            :class="[{ active_color: color === mail_config_data.button_color }]"
            @click.native="chooseColor('button_color', color)"
          />
        </div>
      </div>
      <div class="form-group col-12">
        <label for="feature_color" class="control-label">Feature color:</label>
        <input
          id="feature_color"
          v-model="mail_config_data.feature_color"
          type="text"
          name="feature_color"
          class="form-control"
        />
        <div class="d-block mt-3">
          <ColorSelect
            v-for="color in colors"
            :key="color"
            :style="{ background: color }"
            :class="[{ active_color: color == mail_config_data.feature_color }]"
            @click.native="chooseColor('feature_color', color)"
          />
        </div>
      </div>
      <div class="form-group col-12">
        <h5>Logo</h5>
        <hr />
      </div>
      <div
        class="form-group col-12"
        :class="{ 'is-invalid': errors.has('logo') }"
      >
        <image-uploader
          id="logo"
          url="organization/mail/logo"
          :image-path="mail_config_data.logo"
          @image-changed="uploadSuccess"
        />
      </div>
      <div
        class="form-group col-12"
        :class="{ 'is-invalid': errors.has('logo_max_height') }"
      >
        <label for="logo_max_height" class="control-label"
          >Logo max height:</label
        >
        <div class="input-group">
          <input
            id="logo_max_height"
            v-model="mail_config_data.logo_max_height"
            type="text"
            name="logo_max_height"
            class="form-control"
          />
          <div class="input-group-append">
            <div class="input-group-text">
              px
            </div>
          </div>
        </div>
        <span v-show="errors.has('logo_max_height')" class="invalid-feedback">{{
          errors.first("logo_max_height")
        }}</span>
      </div>
    </div>
    <div class="col-12 col-md-6">
      <h4 class="text-center">
        Email template preview
      </h4>
      <div
        class="m-3 border"
        :style="{ 'background-color': mail_config_data.background_color }"
      >
        <div
          class="text-center p-4"
          :style="{ 'background-color': mail_config_data.header_color }"
        >
          <img
            :src="mail_config_data.logo"
            :style="{ 'max-height': mail_config_data.logo_max_height + 'px' }"
          />
        </div>
        <div class="body text-center pt-6">
          <h3 :style="{ color: mail_config_data.font_color }">
            Lorem ipsum dolor sit amet
          </h3>
          <p
            class="text-center mb-5 pr-5 pl-5"
            :style="{ color: mail_config_data.font_color }"
          >
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas
            egestas gravida diam, laoreet bibendum nunc ornare ut. Vestibulum
            purus ante, consequat nec arcu sed, ullamcorper scelerisque elit.
            Phasellus volutpat placerat orci sed vehicula. Ut sagittis ornare
            orci ut rutrum. Aenean odio magna, auctor faucibus dolor ac,
            scelerisque gravida nibh. Nunc felis nisi, sodales non semper
            porttitor, feugiat ut nunc.
          </p>
          <button
            class="btn btn-lg btn-dark mb-6"
            :style="{
              background: mail_config_data.feature_color,
              color: mail_config_data.button_color,
            }"
          >
            Click me
          </button>
          <hr />
        </div>
        <div
          class="footer text-center p-4"
          :style="{
            'background-color': mail_config_data.footer_color,
            color: mail_config_data.font_color,
          }"
        >
          <p class="small">
            Copyright © {{ current_year }} {{ getName }}, All rights reserved.
          </p>
          <p class="mb-1 small">
            Want to change how you receive these emails?
          </p>
          <p class="small mb-0">
            <span class="pb-2">Unsubscribe from this list</span>
          </p>
        </div>
      </div>
    </div>
    <div class="form-group col-md-12">
      <div class="border col-12" />
    </div>
    <div v-if="showTest" class="form-group col-md-6">
      <button
        :loading="loading"
        class="btn btn-secondary col-12"
        data-title="Click here to send a test mail using your current configuration."
        data-intro=""
        data-step="4"
        @click="openMailTestModal"
      >
        Test email
      </button>
    </div>
    <div class="form-group col-md-6 ml-auto">
      <button
        :loading="loading"
        class="btn btn-secondary col-12"
        data-title="Once you are finished setting up your email, click here to save."
        data-intro=""
        data-step="5"
        data-position="left"
        @click="saveEmailConfig"
      >
        Save configuration
      </button>
    </div>
    <div class="form-group col-md-12">
      <component :is="component" @hideComponent="component = ''" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Multiselect from "vue-multiselect";
import jsonAPI from "@/api";
import ImageUploader from "@/components/UI/ImageUploader.vue";
import ColorSelect from "@/components/UI/ColorSelect.vue";
import MailTestModal from "@/components/UI/MailTestModal.vue";
import countries from "@/helpers/countries";
export default {
  name: "EmailTemplate",
  components: {
    ColorSelect,
    ImageUploader,
    Multiselect,
    MailTestModal,
  },
  provide() {
    return {
      $validator: this.$validator,
    };
  },
  computed: {
    ...mapGetters(["getOrganization"]),
    getName() {
      return this.getOrganization.title ?? "";
    },
  },
  data() {
    return {
      current_year: new Date().getFullYear(),
      mail_config_data: {
        wrapper_color: "transparent",
        background_color: "#c2cae7",
        header_color: "#c2cae7",
        footer_color: "#c2cae7",
        feature_color: "#1fbc9c",
        button_color: "#ffffff",
        font_color: "#000000",
        logo_max_height: 500,
        logo: "",
      },
      current_template: "",
      template_option: "",
      loading: false,
      component: null,
      mail_drivers: [],
      templates: [],
      templatesData: [],
      colors: [
        "",
        "#1fbc9c",
        "#2ecc70",
        "#3398db",
        "#a463bf",
        "#3d556e",
        "#f2c511",
        "#e84b3c",
      ],
      font_colors: ["#ffffff", "#000000"],
      button_colors: ["#ffffff", "#000000"],
      bg_colors: [
        "",
        "#ffffff",
        "#000000",
        "#c2cae7",
        "#d2c1dc",
        "#bfdcd4",
        "#fdd1bb",
      ],
      step_status: {
        key: "create_email_template",
        status: true,
      },
      countries: countries,
      showTest: false,
    };
  },
  mounted() {
    jsonAPI.get("/organization/mail/settings").then((response) => {
      this.mail_drivers = response.data.drivers;
      this.mail_config_data = Object.assign(
        this.mail_config_data,
        response.data.settings
      );

      // get mail driver
      if (response.data.settings.mail_driver) {
        this.mail_driver = response.data.settings.mail_driver;
        this.showTest = true;
        this.$emit("proceed");
      }

      // check if from_mail is empty and use current user email and name
      if (!this.mail_config_data.from_mail) {
        this.mail_config_data.from_mail = this.getUser.email;
        this.mail_config_data.from_name = this.getUser.name;
      }
    });
  },
  methods: {
    async saveEmailConfig() {
      this.loading = true;
      this.$validator.validateAll().then((result) => {
        if (result) {
          const data = {
            organization_id: this.getOrganization.id,
            data: this.mail_config_data,
            group: "template",
          };
          this.createTemplate(data);
        }
        this.loading = false;
      });
    },
    async createTemplate(data) {
      const url = "/configurationData";
      jsonAPI
        .post(url, data)
        .then(() => {
          // mark step complete
          this.$store.dispatch("CHECK_ONBOARDING_STEP", this.step_status);

          window.Bus.$emit("flash-message", {
            text: "Email template created",
            type: "success",
          });
          this.$emit("proceed");
        })
        .catch((error) => {
          Object.keys(error.response.data.errors).forEach((key) => {
            this.$validator.errors.add({
              field: key,
              msg: error.response.data.errors[key][0],
            });
          });
          window.Bus.$emit("flash-message", {
            text: "error main",
            type: "error",
          });
        });
    },
    chooseColor(type, color) {
      this.mail_config_data[type] = color;
    },
    uploadSuccess: function(path) {
      this.mail_config_data.logo = path;
    },
    async setEmailConfig(mailConfigData) {
      this.mail_config_data = Object.assign(
        this.mail_config_data,
        mailConfigData
      );
    },
    openMailTestModal() {
      this.component = "MailTestModal";
      this.$store.dispatch("openModal", {
        title: "Test Mail Configuration",
        sender_email: this.mail_config_data.from_mail,
        sender_name: this.mail_config_data.from_name,
      });
    },
  },
};
</script>

<style scoped></style>
