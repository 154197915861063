export default {
  tags: [
    {
      slug: "repeat_customer",
      name: "Repeat customer",
      color: "#68BC00",
    },
    {
      slug: "vip_customer",
      name: "Vip customer",
      color: "#009CE0",
    },
    {
      slug: "high_revenue",
      name: "High revenue",
      color: "#7B64FF",
    },
    {
      slug: "churning_customer",
      name: "Churning customer",
      color: "#0062B1",
    },
  ],
  actions: [
    {
      name: "Tag repeat customers",
      description: "Mark customers who have made a purchase more than once",
      installed: false,
      automationAction: {
        key: "set_tag",
        config: {
          tag: "repeat_customer",
        },
      },
      event: "OrderCreated",
      delay: 0,
      delay_type: "time_delay",
      delay_unit: "Minutes",
      delay_operator: "-",
      conditions_group: "AND",
      conditions: [
        {
          field: "Amount",
          type: "customer_orders",
          operator: ">=",
          value: "2",
        },
      ],
    },
    {
      name: "Tag vip customers",
      description: "Mark customers who should receive special attention",
      installed: false,
      automationAction: {
        key: "set_tag",
        config: {
          tag: "vip_customer",
        },
      },
      event: "OrderCreated",
      delay: 0,
      delay_type: "time_delay",
      delay_unit: "minutes",
      delay_operator: "-",
      conditions_group: "AND",
      conditions: [
        {
          field: "Amount",
          type: "customer_orders",
          operator: ">=",
          value: "2",
        },
      ],
    },
    {
      name: "Tag vip customers",
      description: "Mark customers who should receive special attention",
      installed: false,
      automationAction: {
        key: "set_tag",
        config: {
          tag: "vip_customer",
        },
      },
      event: "OrderCreated",
      delay: 0,
      delay_type: "time_delay",
      delay_unit: "minutes",
      delay_operator: "-",
      conditions_group: "AND",
      conditions: [
        {
          field: "Amount",
          type: "customer_orders",
          operator: ">=",
          value: "2",
        },
      ],
    },
    {
      name: "Tag churning customers",
      description: "Mark customers who churn",
      installed: false,
      automationAction: {
        key: "set_tag",
        config: {
          tag: "churning_customer",
        },
      },
      event: "OrderCreated",
      delay: 0,
      delay_type: "time_delay",
      delay_unit: "minutes",
      delay_operator: "-",
      conditions_group: "AND",
      conditions: [
        {
          field: "Amount",
          type: "customer_orders",
          operator: ">=",
          value: "3",
        },
        {
          field: "LastOrderPlaced",
          type: "customer_orders",
          operator: "<",
          value: "30 days",
        },
      ],
    },
  ],
};
