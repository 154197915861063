export default {
  tags: [
    {
      slug: "information_missing",
      name: "Information missing",
      color: "#FE9200",
    },
    {
      slug: "payment_failed",
      name: "Payment failed",
      color: "#F44E3B",
    },
    {
      slug: "out_of_stock",
      name: "Out of stock",
      color: "#FCDC00",
    },
  ],
  actions: [
    {
      name: "Send information missing email",
      description:
        "Send an email to the customer if an order is missing information",
      installed: false,
      automationAction: {
        key: "mail",
        config: {
          to: "!customer",
          subject:
            "Sorry for the inconvenience, but are missing some information",
          content:
            "<p>Hey {{billingAddress.givenName}}</p>" +
            "<p>It seems like we are missing some information about your order</p>" +
            "<p>Please reach out to our customer service on 'telephone' so we can get the final information and complete your order.</p>",
        },
      },
      event: "OrderUpdated",
      delay: 0,
      delay_type: "time_delay",
      delay_unit: "Minutes",
      delay_operator: "-",
      conditions_group: "AND",
      conditions: [
        {
          field: "tags",
          type: "order",
          operator: "contains",
          value: "information_missing",
        },
      ],
    },
  ],
};
