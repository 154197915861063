export default {
  filters: [
    {
      name: "Filter by store",
      description: "Setup a filter which shows orders from a specific store.",
      installed: false,
      type: "order",
      conditions_group: "AND",
      conditions: [
        {
          type: "order",
          field: "channel_name",
          value: "My shop",
          operator: "==",
        },
      ],
    },
  ],
};
