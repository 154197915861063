<template>
  <button class="btn btn-primary" type="submit" :disabled="loading" @click="submit">
    <Spinner v-if="loading" />
    Connect to {{ provider }}
  </button>
</template>

<script>
import { mapGetters } from "vuex";
import jsonAPI from "@/api";
import Spinner from "@/components/UI/Spinner.vue";
import utilities from "@/helpers/utilities";
export default {
  name: "ConnectButton",
  components: {
    Spinner,
  },
  props: {
    provider: {
      type: String,
      required: true,
    },
    organization: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters(["getUser"]),
    url: () => `/oauth/${this.provider}`,
  },
  data() {
    return {
      loading: false,
    };
  },
  mounted() {
    window.addEventListener("message", this.onMessage, false);
    console.log("Message listener added")
  },
  beforeDestroy() {
    window.removeEventListener("message", this.onMessage);
  },
  methods: {
    async submit() {
      this.loading = true;
      const newWindow = utilities.openWindow("", "Login");
      const { data } = await jsonAPI.post(
        `/system/connect/${this.provider.toLowerCase()}`
      );
      const url = data.url;
      newWindow.location.href = url;
      this.loading = false;
      this.$emit('connecting');
    },
    onMessage(e) {
      this.loading = false;
      if (e.origin !== window.origin || !e.data.success) {
        return;
      }
      // jsonAPI.post("/marketing/event", {
      //   action: "connect-woocommerce",
      //   email: this.getUser.email,
      // });
      console.log("Successful connect")
      this.$emit("successfulConnect");
    },
  },
};
</script>
