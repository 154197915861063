<template>
    <component :is="layout">
        <div class="container-md">
            <div class="row fadeInUp" role="main">
                <div class="col-12 col-md-8 offset-md-2">
                    <div class="form-row">
                        <div class="col-12">
                            <div class="card border-0 shadow-sm mb-6">
                                <div class="card-body">
                                    <div class="d-flex flex-grow">
                                        <div
                                        v-for="step in steps"
                                        :key="step.step"
                                        class="flex-fill mb-5 border-bottom"
                                        >
                                        <p class="text-center align-middle">
                                            <CheckmarkCircleLine
                                            :height="'25'"
                                            class="inline mr-2"
                                            style="margin-top: -5px;"
                                            :fill="[step.completed ? '#3FDC75' : '#000000']"
                                            />
                                            {{ step.name }}
                                        </p>
                                        </div>
                                    </div>
                                     <component
                                        :is="getCurrentStep.component"
                                        v-if="getCurrentStep"
                                        @proceed="stepComplete"
                                    />
                                    <p v-else class="text-center">
                                        Email Setup completed successfully
                                    </p>
                                </div>
                                <div v-if="!setupComplete" class="card-footer">
                                    <button v-if="step > 1" class="btn btn-light" @click="step--">
                                        Prev
                                    </button>
                                    <button
                                        v-if="step < steps.length && showNext"
                                        class="btn btn-primary float-right"
                                        @click="nextStep()"
                                    >
                                        Next
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </component>
</template>

<script>
import Default from "@/layouts/default.vue";
import EmailConfigurator from "@/components/Onboarding/EmailConfigurator.vue";
import EmailTemplate from "@/components/Onboarding/EmailTemplate.vue";
import CheckmarkCircleLine from "@/components/Svg/CheckmarkCircleLine.vue";
import { mapGetters } from 'vuex';
import confetti from "canvas-confetti";

var myCanvas = document.getElementById("canvas");
var myConfetti = confetti.create(myCanvas, { resize: true });

export default {
    name: "ConfigureEmailing",
    components: {
        Default,
        EmailConfigurator,
        EmailTemplate,
        CheckmarkCircleLine
    },
    data() {
        return {
            layout: "Default",
            steps: [
                {
                    step: 1,
                    name: "Email configuration",
                    key: "configure_driver",
                    component: "EmailConfigurator",
                    completed: false,
                },
                {
                    step: 2,
                    name: "Email template",
                    key: "create_email_template",
                    component: "EmailTemplate",
                    completed: false,
                },
            ],
            step: 1,
            showNext: false,
            setupComplete: false,
        }
    },
    computed: {
        ...mapGetters(["getOrganization", "getUser"]),
        getCurrentStep() {
        const steps = this.steps.filter((elem) => {
            return !elem.completed;
        });
        if (steps) {
            return steps.shift();
        }
        return null;
        },
    },
    methods: {
        hasIncomplete() {
            return this.steps.some(function(st) {
                return !st.completed;
            });
        },
        stepComplete() {
            this.showNext = true;
        },
        nextStep() {
            const currentStep = this.getCurrentStep;
            this.steps.forEach((step, index) => {
                if (step.key === currentStep.key) {
                this.steps[index].completed = true;
                }
            });
            const hasIncomplete = this.hasIncomplete();
            if (!hasIncomplete) {
                this.setupComplete = true;
                setTimeout(function() {
                    var end = Date.now() + 2000;

                    var interval = setInterval(function() {
                    if (Date.now() > end) {
                        return clearInterval(interval);
                    }

                    myConfetti({
                        startVelocity: 30,
                        spread: 360,
                        ticks: 60,
                        origin: {
                        x: Math.random(),
                        y: Math.random() - 0.2,
                        },
                    });
                    }, 200);
                }, 500);
            }
        },
    }
}
</script>
