export default {
  actions: [
    {
      name: "Win back one time customers: 30 days",
      description: "Send email to customers who haven't returned in 30 days",
      installed: false,
      automationAction: {
        key: "mail",
        config: {
          to: "!customer",
          subject: "Just getting started",
          content:
            "<p>First of all, we want to give you a warm welcome. Thanks for joining us. Our team and I, are happy to have you as our client and to be able to send you a few welcoming emails.<br/></p>\n" +
            "<p>Best regards</p>\n" +
            "<p>{{organization.name}}</p>",
        },
      },
      event: "OrderCreated",
      delay: 30,
      delay_type: "time_delay",
      delay_unit: "days",
      delay_operator: "-",
      conditions_group: "AND",
      conditions: [
        {
          field: "Amount",
          type: "customer_orders",
          operator: "==",
          value: "1",
        },
      ],
    },
    {
      name: "Win back one time customers: 45 days",
      description: "Send email to customers who haven't returned in 45 days",
      installed: false,
      automationAction: {
        key: "mail",
        config: {
          to: "!customer",
          subject: "What to expect from us",
          content:
            "<p>Hey {{billingAddress.givenName}}</p>" +
            "<p>The emails you'll receive from {{organization.name}} will contain anything from information about how to get more value from us to recommendations that would be relevant to you.</p>",
        },
      },
      event: "OrderCreated",
      delay: 45,
      delay_type: "time_delay",
      delay_unit: "days",
      delay_operator: "-",
      conditions_group: "AND",
      conditions: [
        {
          field: "Amount",
          type: "customer_orders",
          operator: "==",
          value: "1",
        },
      ],
    },
    {
      name: "Win back one time customers: 70 days",
      description: "Send email to customers who haven't returned in 70 days",
      installed: false,
      automationAction: {
        key: "mail",
        config: {
          to: "!customer",
          subject: "Let's get to know each other",
          content:
            "<p>Hey {{billingAddress.givenName}}</p>" +
            "<p>I'm {{account.name}}, I think it's time to let you know a bit more. " +
            "{{organization.name}} exists to serve you and other clients. " +
            "We have a history of happy customers and hope to add you to the list. " +
            "If there is anything that you think we can do better. " +
            "Don't hesitate to drop me an email at {{organization.email}}</p>" +
            "<p></p>" +
            "<p>Best regards</p>" +
            "<p>{{organization.name}}</p>",
        },
      },
      event: "OrderCreated",
      delay: 70,
      delay_type: "time_delay",
      delay_unit: "days",
      delay_operator: "-",
      conditions_group: "AND",
      conditions: [
        {
          field: "Amount",
          type: "customer_orders",
          operator: "==",
          value: "1",
        },
      ],
    },
    {
      name: "Win back one time customers: 100 days",
      description: "Send email to customers who haven't returned in 100 days",
      installed: false,
      automationAction: {
        key: "mail",
        config: {
          to: "!customer",
          subject: "Join us on Social Media",
          content:
            "<p>Hey {{billingAddress.givenName}}</p>" +
            "<p>We wouldn't flourish without you! That's why we'd like you to enjoy our social media work. " +
            "Yes, we're there too! Keep up with us and we'll be eager to provide even more quality content.<br/>" +
            "- Insert button with social profile -</p>" +
            "<p>Check out what's been going on</p>" +
            "<p>It's been 6 months, but we should stay in touch. We have a lot of interesting news to offer you. " +
            "Check out the news in our shop to get updated on the most recent updates.<br/>" +
            "- Insert button with shop link -</p>",
        },
      },
      event: "OrderCreated",
      delay: 100,
      delay_type: "time_delay",
      delay_unit: "days",
      delay_operator: "-",
      conditions_group: "AND",
      conditions: [
        {
          field: "Amount",
          type: "customer_orders",
          operator: "==",
          value: "1",
        },
      ],
    },
    {
      name: "Win back one time customers: 180 days",
      description: "Send email to customers who haven't returned in 180 days",
      installed: false,
      automationAction: {
        key: "mail",
        config: {
          to: "!customer",
          subject: "Check out what's been going on",
          content:
            "<p>Hey {{billingAddress.givenName}}</p>" +
            "<p>It's been 6 months, but we should stay in touch. We have a lot of interesting news to offer you. " +
            "Check out the news in our shop to get updated on the most recent updates.<br/>" +
            "- Insert button with shop link -</p>",
        },
      },
      event: "OrderCreated",
      delay: 180,
      delay_type: "time_delay",
      delay_unit: "days",
      delay_operator: "-",
      conditions_group: "AND",
      conditions: [
        {
          field: "Amount",
          type: "customer_orders",
          operator: "==",
          value: "1",
        },
      ],
    },
    {
      name: "Win back one time customers: 1 year anniversary",
      description: "Send email to customers who ordered 1 year ago anniversary",
      installed: false,
      automationAction: {
        key: "mail",
        config: {
          to: "!customer",
          subject: "Happy 1-year purchase anniversary",
          content:
            "<p>You've probably forgotten all about us. But we'd like to stay in touch. Remember the time when you bought {{order.orderItems.0.name}}. " +
            "We we're excited for your order and hope that you feel like ordering again soon. Thanks again for your order and support of our brand. </p>" +
            "<p></p>" +
            "<p>Best regards</p>" +
            "<p>{{organization.name}}</p>",
        },
      },
      event: "OrderCreated",
      delay: 1,
      delay_type: "time_delay",
      delay_unit: "year",
      delay_operator: "-",
      conditions_group: "AND",
      conditions: [
        {
          field: "Amount",
          type: "customer_orders",
          operator: "==",
          value: "1",
        },
      ],
    },
  ],
};
