export default {
  actions: [
    {
      name: "Thank loyal customer: Thank you for your order",
      description: "Thank your customers for their business",
      installed: false,
      automationAction: {
        key: "mail",
        config: {
          to: "!customer",
          subject: "Thank you for your order",
          content:
            "<p>Hey {{billingAddress.givenName}}</p>" +
            "<p>Just wanted to say thanks for your order :)</p>" +
            "<p>We built this shop because we care about these products. It's really nice that you share some of our love.</p>" +
            "<p>Hope to see you back soon.</p>",
        },
      },
      event: "OrderCreated",
      delay: 0,
      delay_type: "time_delay",
      delay_unit: "minutes",
      delay_operator: "-",
      conditions_group: "AND",
      conditions: [
        {
          field: "Amount",
          type: "customer_orders",
          operator: "==",
          value: "1",
        },
      ],
    },
    {
      name: "Thank loyal customer: Review experience",
      description: "Send email to ask customers to review their experience",
      installed: false,
      automationAction: {
        key: "mail",
        config: {
          to: "!customer",
          subject: "Could we ask you for a review",
          content:
            "<p>Hey {{billingAddress.givenName}}</p>" +
            "<p>We were hoping you would spend 5 minutes giving us a review of your experience</p>" +
            "<p>In order to keep improving our business and relations with our customers we would love if you would review your experience with us.</p>" +
            "<p>Please click the link below to visit the review page</p>" +
            "<button class='btn btn-lg btn-dark mb-6'>Review</button>",
        },
      },
      event: "OrderCreated",
      delay: 1,
      delay_type: "time_delay",
      delay_unit: "days",
      delay_operator: "-",
      conditions_group: "AND",
      conditions: [],
    },
    {
      name: "Thank loyal customer: Social media",
      description: "Send email to ask customers to like you on social media",
      installed: false,
      automationAction: {
        key: "mail",
        config: {
          to: "!customer",
          subject: "Please like us on social media",
          content:
            "<p>Hey {{billingAddress.givenName}}</p>" +
            "<p>We hope you had a pleasent experience</p>" +
            "<p>We continue to share news and new products on our social media accounts</p>" +
            "<p>If you liked what you saw in the shop please consider liking us on social media.</p>",
        },
      },
      event: "OrderCreated",
      delay: 2,
      delay_type: "time_delay",
      delay_unit: "days",
      delay_operator: "-",
      conditions_group: "AND",
      conditions: [],
    },
  ],
};
